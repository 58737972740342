import * as React from 'react';
import { Button } from '../styles/StyledButton';
import {
  InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine,
  Heading, Subtitle, Subtitle2, Subtitle3, BtnWrap, ImgWrap, Img, SubtitleWithMail, 
} from '../styles/StyledInfo';

const Info = ({
  lightBg, id, imgStart, topLine, lightText, headline, darkText,
  description, description1, description2, description3, description4, description5, description3Mail, description5Mail, 
  buttonLabel, alt, img, primary, dark, dark2, preMailDescription, mailaddress, postMailDescription }) => {
    return (
        <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
            <InfoRow imgStart={imgStart}>
                <Column1>
                <TextWrapper>
                    <TopLine>{topLine}</TopLine>
                    <Heading lightText={lightText}>{headline}</Heading>
                    <Subtitle darkText={darkText}>{description} </Subtitle>
                    <SubtitleWithMail darkText={darkText}>{preMailDescription} <a href={"mailto:" + mailaddress}>{mailaddress}</a> {postMailDescription}</SubtitleWithMail>
                    <Subtitle darkText={darkText}>{description1}</Subtitle>
                    <Subtitle2 darkText={darkText}>{description2}</Subtitle2>
                    <Subtitle3 darkText={darkText}>{description3} <br></br> {description3Mail ? <a href={"mailto:"+description3Mail}>{description3Mail}</a> : ""}</Subtitle3>
                    
                    <Subtitle2 darkText={darkText}>{description4}</Subtitle2>
                    <Subtitle3 darkText={darkText}>{description5} <br></br> {description5Mail ? <a href={"mailto:"+description5Mail}>{description5Mail}</a> : ""}</Subtitle3>
                    <BtnWrap>
                    <Button
                        to='home' smooth={true} duration={500} spy={true} exact='true'
                        offset={-80} primary={primary ? 1 : 0}
                        dark={dark ? 1 : 0} dark2={dark2 ? 1 : 0}
                        Buttonvisible={primary ? 1 : 0}
                    >
                        {buttonLabel}
                    </Button>
                    </BtnWrap>
                </TextWrapper>
                </Column1>
                <Column2>
                <ImgWrap>
                    <Img src={img} alt={alt} />
                </ImgWrap>
                </Column2>
            </InfoRow>
            </InfoWrapper>
        </InfoContainer>
    );
};

export default Info;
