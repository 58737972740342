/* eslint-disable react/prop-types */
import * as React from 'react'
import Icon1 from '../../static/images/assess.svg'
import Icon2 from '../../static/images/report.svg'
import Icon3 from '../../static/images/assess.svg'
import {
    ServicesContainer, ServicesH1, ServicesIntro, ServicesWrapper, ServiceIntroWrapper,
    ServicesCard, ServicesIcon, ServicesH2, ServicesP,
} from '../styles/StyledServices'

const Services = () => {
  return (
      <ServicesContainer id="services">
          <ServiceIntroWrapper>
              <ServicesH1>Our Services</ServicesH1>
              
          </ServiceIntroWrapper>
          <ServicesWrapper>
              <ServicesCard>
                  <ServicesIcon src={Icon1} />
                  <ServicesH2>Cyber Security <br/> Programs</ServicesH2>
                  <ServicesP>
                      We provide continuous visualization of cyber risks and cyber security level, and
                      prioritization, planning and follow-ups of key improvements.
                  </ServicesP>
              </ServicesCard>
              <ServicesCard>
                  <ServicesIcon src={Icon2} />
                  <ServicesH2>DevSecOps <br/> Services</ServicesH2>
                  <ServicesP>
                      We provide devsecops services for design and implementation of security controls, modern apps and infrastructure, and development of tooling, processes, and teams.
                  </ServicesP>
              </ServicesCard>
              <ServicesCard>
                  <ServicesIcon src={Icon3} />
                  <ServicesH2>Due <br/> Diligence</ServicesH2>
                  <ServicesP>
                      We provide support in operational due diligence, focusing on cyber risk and security controls in acquisition target or an outsourced IT service delivery.
                  </ServicesP>
              </ServicesCard>
          </ServicesWrapper>
      </ServicesContainer>
  );
}

export default Services
