import React from 'react';
import Hero from '../components/Hero';
import Info from '../components/Info';
import { homeObjOne, homeObjTwo, homeObjThree } from '../components/InfoData';
import Services from '../components/Services';
import { Seo } from '../components/Seo';

const IndexPage = () => {
  return (
    <>
      <Hero />
      <Info {...homeObjOne} />
      <Info {...homeObjTwo} />
      <Services />
      <Info {...homeObjThree} />
    </>
  );
};
export const Head = () => <Seo />;
export default IndexPage;
