import styled from 'styled-components'

export const ServicesContainer = styled.div`
  display: flex;
  flex-flow: row wrap; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--anzr-blue);
  padding: 5rem 0rem;
 `
 export const ServiceIntroWrapper = styled.div`
  display: flex;
  max-width: 1000px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ServicesWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  flex: 1 0 400px;
  flex-flow: column; 
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 32px;


  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 786px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`
    // align-items: stretch;

export const ServicesCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  align-items: center;
  border-radius: 10px;
  padding:  2rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
  }
`
  // align-items: stretch;

export const ServicesIcon = styled.img`
  height: 120px;
  width: 120px;
  margin-bottom: 2rem;

`
export const ServicesH1 = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 2rem;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }  
`
export const ServicesIntro = styled.p`
  font-size: 1.2rem;
  line-height: 1.4;
  margin-bottom: 3rem;
  text-align: center;
  font-weight:300;
  color: #fff;
`

export const ServicesH2 = styled.h2`
  font-size: 1.4rem;
  margin-bottom: 1rem;
  text-align: center;
  &::after {
    background-color: var(--anzr-green);
    margin: 1rem auto;
    position: relative;
    height: 2px;
    width: 40px;
    border-radius:5px;
    content: "";
    display: block;
    border: 2px solid var(--anzr-green);
  }
`

export const ServicesP = styled.p`
  font-size: 1rem;
  margin-bottom:1rem;
  text-align: center;
  font-weight:300;
`


