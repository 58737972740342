export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lighttextDesc: true,
    ButtonVisible: false,
    topLine: 'About Anzr',
    headline: 'Making real difference',
    description: "Anzr provides services within infrastructure as code and cyber security, enabling our customers' transformation and improved security",
    buttonLabel: 'Get started',
    imgStart: false,
    img: '../images/pattern.svg',
    alt: 'pattern',
    dark: true,
    primary: true,
    darkText: false
  };
  
  export const homeObjTwo = {
    id: 'careers',
    lightBg: true,
    lightText: false,
    lighttextDesc: false,
    ButtonVisible: false,
    topLine: 'Careers',
    headline: 'We are looking for exceptional talent to join our team',
    preMailDescription: 'We always look for talented people with different levels of professional experience. Requirements are a genuine interest in our clients, technology, cyber security, and to have fun at work! Give us a call or send an email at', 
    mailaddress: "hello@anzr.co",
    postMailDescription: "if this profile suits you!",
    buttonLabel: 'contact',
    imgStart: true,
    img: '../images/Anzrcareers2.png',
    alt: 'Anzrcareers',
    dark: false,
    primary: false,
    darkText: true
  };
  
  export const homeObjThree = {
    id: 'contact',
    lightBg: true,
    lightText: false,
    lighttextDesc: false,
    ButtonVisible: true,
    topLine: 'Contact Us',
    headline: ' Let’s talk!',
    description: 'We are happy to help you in the complex world of infrastructure as code and cyber security, impacting technology, people and processes. ',
    preMailDescription: 'Email us at',
    mailaddress: 'hello@anzr.co',
    postMailDescription: 'or contact our partners.',
    description2: 'Magnus Blom',
    description3: '+46704547633',
    description4: 'Robert Teir',
    description5: '+46735255184',
    description3Mail: 'magnus@anzr.co',
    description5Mail: 'robert@anzr.co',
    buttonLabel: 'Läs mera',
    imgStart: false,
    img: '../images/pattern.svg',
    alt: 'pattern',
    primary: false,
    darkText: true
  };