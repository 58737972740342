import styled from 'styled-components';
import { MdArrowForward, MdKeyboardArrowRight } from 'react-icons/md';

export const HeroContainer = styled.div`
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 30px;
  height: 800px;
  position: relative;
  z-index: 1;
  &::before {
    content: '';
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0.2), rgba(0,0,0,0.6));
    z-index: 2;
  }
`
export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`
export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
export const HeroH1 = styled.h1`
  color: var(--anzrblue);
  font-size: 4.2rem;
  line-height: 1;
  text-align: left;

  @media screen and (max-width: 768px) {
    font-size: 48px;
    line-height: 1;
  }
  @media screen and (max-width: 480px) {
    font-size: 32px;
    line-height: 1;
  }
`

export const HeroP = styled.p`
  margin-top: 3rem;
  color: var(--anzr-blue);
  font-size: 24px;
  font-weight: 100;
  text-align: left;
  max-width: 1100px;

  @media screen and (max-width: 768px) {
    font-size: 24px
  }
  @media screen and (max-width: 480px) {
    font-size: 18px
  }
`
export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`