import * as React from 'react';
import { useState } from 'react';
import {
  HeroContainer, HeroBg, HeroContent, HeroH1, HeroP,
  HeroBtnWrapper, ArrowForward, ArrowRight,
} from '../styles/StyledHero';
import { Button } from '../styles/StyledButton';

const Hero = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id='home'>
      <HeroBg>{/* <VideoBg autoPlay playsInline loop muted src={Video} type='video/mp4' /> */}</HeroBg>
      <HeroContent>
        <HeroH1>We ensure that our clients do not fall victim to cyber threats.</HeroH1>
        <HeroP>
          A strategic partner with unique insights and longtime experience of infrastructure as code and
          cyber security.
        </HeroP>
        <HeroBtnWrapper>
          {/* <Button to='about' onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true' smooth={true} duration={500} spy={true} exact='true' offset={-80}> */}
          <Button to='about' onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true'>
            Read more {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
